import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container/Container';

const Home: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <main>
          <h1>Home page</h1>
          <p>{t('title')}</p>
        </main>
      </Container>
    </>
  );
};

export default Home;
