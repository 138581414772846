import { postData } from './fetchData';
import { API_URL_DEV, AUTHENTICATED_PATH } from '../utils/constants';

interface IToken {
  data: {
    token: string;
  };
}

const exchangeAuthenticationCode = async (code: string): Promise<string> => {
  const response = await postData<IToken>(`${API_URL_DEV}${AUTHENTICATED_PATH}`, { code });
  return response.data.token;
};

export default exchangeAuthenticationCode;
