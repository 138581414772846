import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import './i18n';

import App from './App';

import './index.scss';

const createApp = () => (
  <React.StrictMode>
    <Suspense fallback="Loading...">
      <App />
    </Suspense>
  </React.StrictMode>
);

ReactDOM.render(createApp(), document.getElementById('root'));
