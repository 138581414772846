import { createContext } from 'react';

interface IAppContext {
  isAuthenticated: boolean;
  sessionToken: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAuthentication?: (history?: any) => void;
  handleLogout?: () => void;
}

const defaultAppContent = {
  isAuthenticated: false,
  sessionToken: ''
};

const AppContext = createContext<IAppContext>({ ...defaultAppContent });

export default AppContext;
