import React, { FC } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import Error from './pages/Error/Error';

import GlobalState from './contexts/GlobalState';
import PrivateRoute from './components/PrivateRoute';

const App: FC = () => (
  <GlobalState>
    <Router>
      <Switch>
        <PrivateRoute exact path="/">
          <Home />
        </PrivateRoute>
        <Route path="*">
          <Error errorContext="Error" redirectUrl="/" redirectText=" Home page" />
        </Route>
      </Switch>
    </Router>
  </GlobalState>
);

export default App;
