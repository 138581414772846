import React, { FC, useState } from 'react';
import AppContext from './AppContext';
import exchangeAuthenticationCode from '../api/exchangeAuthenticationCode';
import removeURLParameter from '../utils/removeUrlParam';
import { LOGIN_URL } from '../utils/constants';

const GlobalState: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [sessionToken, setSessionToken] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAuthentication = async (history?: any): Promise<void> => {
    if (process.env.NODE_ENV === 'development') {
      setSessionToken('devToken');
      setIsAuthenticated(true);

      return;
    }

    const tokenFromStorage = sessionStorage.getItem('sessionToken');
    const queryParams = new URL(window.location.href).searchParams;
    const code = queryParams.get('code');

    if (code) {
      try {
        const token = await exchangeAuthenticationCode(code);

        sessionStorage.setItem('sessionToken', token);
        setSessionToken(token);
        setIsAuthenticated(true);

        const clearUrl = removeURLParameter(window.location.pathname, 'code');
        history.replace(clearUrl);
      } catch (error) {
        window.location.href = LOGIN_URL;
      }
    } else if (tokenFromStorage) {
      setSessionToken(tokenFromStorage);
      setIsAuthenticated(true);
    } else {
      window.location.href = LOGIN_URL;
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setSessionToken('');
    sessionStorage.removeItem('sessionToken');
  };

  return (
    <AppContext.Provider
      value={{ isAuthenticated, sessionToken, handleAuthentication, handleLogout }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default GlobalState;
