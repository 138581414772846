import React, { FC, useContext, useState, useEffect } from 'react';
import { Progress } from 'antd';
import AppContext from '../../contexts/AppContext';
import getUserProfile from '../../api/getUserProfile';

const UserInfo: FC = () => {
  const { sessionToken, handleLogout } = useContext(AppContext);
  const [user, setUser] = useState({
    emailVerified: false,
    updatedAt: '',
    createdAt: '',
    storageUsed: 0,
    email: '',
    picture: '',
    name: '',
    storageAvailable: 0
  });
  const [storageDegrees, setStorageDegrees] = useState(0);

  const convertBytesToGigabytes = (bytes: number): number => {
    const gigabytes = bytes / 1024 ** 3;
    return gigabytes;
  };

  const calculateAvailableStorage = (currentValue: number, maxValue: number): number => {
    const currentDegrees = (currentValue * 360) / maxValue;
    return currentDegrees;
  };

  useEffect(() => {
    getUserProfile(sessionToken).then((userFromResponse) => {
      setUser(userFromResponse);
      const currentDeg = calculateAvailableStorage(
        userFromResponse.storageUsed,
        userFromResponse.storageAvailable
      );
      setStorageDegrees(currentDeg);
    });
  }, []);

  return (
    <div className="sidebar__user user">
      <img className="user__img" src={user.picture} alt={user.name} />
      <p className="user__name">{user.name}</p>
      <ul className="user__links">
        <li>
          <a href="/" className="links__item">
            My account
          </a>
        </li>
        <li>
          <button
            type="button"
            className="links__item links__item--exit button--styles-reset"
            onClick={() => handleLogout?.()}
          >
            Logout
          </button>
        </li>
      </ul>
      <div className="user__progress-bar progress-bar">
        <Progress type="circle" percent={storageDegrees} className="progress-bar__bar" />
        <div className="progress-bar__inner inner">
          Used
          <br />
          storage
          <div className="inner__value-wrapper">
            <span className="value value--current">
              {convertBytesToGigabytes(user.storageUsed)}
              GB
            </span>
            &nbsp;/&nbsp;
            <span className="value value--max">
              {convertBytesToGigabytes(user.storageAvailable)}
              GB
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
