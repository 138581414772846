import { IApiErrorResponse, IApiUnauthorizedResponse } from '../utils/types/IApiResponse';
import { LOGIN_URL } from '../utils/constants';

const fetchData = async <T>(
  url: string,
  payload = {}
): Promise<T & IApiErrorResponse & IApiUnauthorizedResponse> => {
  const response = await fetch(url, payload);

  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = LOGIN_URL;
    } else {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }
  }

  return response.json();
};

export const postData = async <T>(
  url: string,
  requestBody = {},
  token?: string | null
): Promise<T & IApiErrorResponse & IApiUnauthorizedResponse> => {
  const authenticatedToken = token
    ? {
        Authorization: `Bearer ${token}`
      }
    : null;

  const response = await fetchData<T>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authenticatedToken },
    body: JSON.stringify(requestBody)
  });

  return response;
};

export const getData = async <T>(
  url: string,
  token: string | null
): Promise<T & IApiErrorResponse & IApiUnauthorizedResponse> => {
  const response = await fetchData<T>(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response;
};
