import React, { FC, useEffect, useContext } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import AppContext from '../contexts/AppContext';

export const PrivateRoute: FC<RouteProps> = (props) => {
  const { isAuthenticated, handleAuthentication } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    handleAuthentication?.(history);
  }, [isAuthenticated]);

  return isAuthenticated ? <Route {...props} /> : <>{console.log('loading...')}</>;
};

export default PrivateRoute;
