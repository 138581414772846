import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="sidebar__footer footer">
      <p className="footer__copyright">
        © Copyright 2020 SkIP
        <br />
        Kyiv, Ukraine
      </p>
      <ul className="footer__list list">
        <li className="list__item">
          <a className="item__link" href="/#">
            {t('termsOfService')}
          </a>
        </li>
        <li className="list__item">
          <a className="item__link" href="/#">
            {t('privacyPolicy')}
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
