import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface IError {
  errorContext: string;
  redirectUrl: string;
  redirectText: string;
}

const Error: FC<IError> = ({ errorContext, redirectUrl, redirectText }) => (
  <>
    <h1>{errorContext || 'Error'}</h1>
    <p />
    <Link to={redirectUrl || '/'}>
      Go to
      {redirectText || ' Home page'}
    </Link>
  </>
);

export default Error;
