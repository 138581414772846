import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import Sidebar from '../Sidebar/Sidebar';

const { Content, Header } = Layout;

const Container: FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`main-container ${collapsed ? 'sidebar--is-active' : ''}`}>
      <Sidebar />
      <Layout className="main-container__content content">
        <Header className="content__header header">
          <button
            className="header__toggle-sidebar button--styles-reset"
            type="button"
            aria-label={collapsed ? 'Hide Sidebar' : 'Expand Sidebar'}
            aria-expanded={collapsed ? 'true' : 'false'}
            onClick={() => toggleSidebar()}
          >
            <div
              className={`hamburger__button ${collapsed ? 'hamburger__button--active' : ''}`}
              aria-hidden="true"
            >
              <span />
            </div>
            {t('menu')}
          </button>
        </Header>
        <Content className="content__body">{children}</Content>
      </Layout>
    </div>
  );
};

export default Container;
