import { getData } from './fetchData';
import { API_URL_DEV, GET_USER_PROFILE_PATH } from '../utils/constants';

interface IUser {
  emailVerified: boolean;
  updatedAt: string;
  createdAt: string;
  storageUsed: number;
  email: string;
  picture: string;
  name: string;
  storageAvailable: number;
}
interface IUserResponse {
  data: {
    user: IUser;
  };
}

const getUserProfile = async (token: string): Promise<IUser> => {
  if (process.env.NODE_ENV === 'development') {
    return {
      emailVerified: true,
      updatedAt: '2020-12-26T21:01:55.734Z',
      createdAt: '2020-12-26T20:44:37.077Z',
      storageUsed: 0,
      email: 'mr.russell.swift@gmail.com',
      picture:
        'https://lh3.googleusercontent.com/a-/AOh14GiAUbOuvcYrxR-ly5juirnwSi0M-9ZsIg2fRnD2EWM=s96-c',
      name: 'Ruslan Tereshchenko',
      storageAvailable: 10737418240
    };
  }

  const response = await getData<IUserResponse>(`${API_URL_DEV}${GET_USER_PROFILE_PATH}`, token);
  return response.data.user;
};

export default getUserProfile;
