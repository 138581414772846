import React, { FC } from 'react';
import Navigation from './Navigation';
import UserInfo from './UserInfo';
import Footer from './Footer';

import './Sidebar.scss';

const Logo: FC = () => (
  <img className="sidebar__logo" src="./images/skip-logo.svg" alt="Skip logo" />
);

const Sidebar: FC = () => (
  <aside className="sidebar">
    <div style={{ width: '100%' }}>
      <Logo />
      <Navigation />
    </div>
    <UserInfo />
    <Footer />
  </aside>
);
export default Sidebar;
