import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const Navigation: FC = () => (
  <nav className="sidebar__nav nav">
    <ul className="nav__list list">
      <li className="list__item">
        <Link to="/" className="item__link item__link--active">
          <img className="link__img" src="./images/icon-file.svg" alt="" />
          My works
        </Link>
      </li>
    </ul>
  </nav>
);

export default Navigation;
